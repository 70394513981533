import cn from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import * as styles from "./Graph.module.scss";


const getMax = (data) => {
  return Math.max(...Object.values(data));
};

const Graph = ({
  className,
  data = {},
}) => {
  const [sortedData, setSortedData] = useState(Object.entries(data));
  const [sortToggle, toggleSorting] = useState(false);

  useEffect(() => {
    setSortedData(
      sortToggle
        ? Object.entries(data)
            .sort(([,a],[,b]) => a-b)
            .reverse()
        : Object.entries(data)
    );
  }, [data, sortToggle]);

  return (
    <table
      className={cn(styles.el, className)}
      style={{ "--total": getMax(data) }}
      onClick={() => toggleSorting(b => !b)}
    >
      <tbody>
        {sortedData.map(([label, count]) => (
          <tr key={label}>
            <th>{label}</th>
            <td>
              <div
                className={cn(styles.bar, label.replaceAll(" ", "-") )}
                style={{ "--count": count }}
              />
            </td>
            <td>{count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Graph;

Graph.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};
