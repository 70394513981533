import cn from "classnames";
import { Link, graphql } from "gatsby";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import Album from "components/Album";
import Graph from "components/Graph";
import { Grid, GridItem } from "components/Grid/Grid";
import Icon from "components/Icon";
import Layout from "components/Layout";
import Number from "components/Number";
import {
  augmentAlbums,
  byArtist,
  buildDates,
  hostsByPlayed,
  processTracks,
  secondsToDuration,
  toMinutes,
  toDuration,
  years,
} from "utils";

import * as styles from "./Home.module.scss";

const Home = ({ data }) => {
  const allAlbums = augmentAlbums(data.allAlbumsYaml.nodes);
  const [currentHost, setCurrentHost] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [latestAlbum, setLatestAlbum] = useState();
  const [theAlbums, setTheAlbums] = useState(allAlbums);
  const [theHosts] = useState(hostsByPlayed(allAlbums));
  const [theTracks, setTheTracks] = useState(processTracks(theAlbums));
  const [theYears, setTheYears] = useState(years(allAlbums));
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    theAlbums && setTheTracks(processTracks(theAlbums));
  }, [theAlbums]);

  const selectHost = (host) => {
    setCurrentHost(host);
    setCurrentYear(null);
    if (host) {
      const albums = _.filter(allAlbums, {host: host});
      setTheAlbums(albums);
      setTheYears(years(_.filter(allAlbums, {host: host})));
    } else {
      setTheAlbums(allAlbums);
      setTheYears(years(allAlbums));
    }
  };

  const selectYear = (year) => {
    setCurrentYear(year);
    setCurrentHost(null);
    if (year) {
      const albums = _.filter(allAlbums, a => a.played_on.slice(0,4) === `${year}`);
      setTheAlbums(albums);
      setTheYears(years(_.filter(allAlbums, a => a.played_on.slice(0,4) === `${year}`)));
    } else {
      setTheAlbums(allAlbums);
      setTheYears(years(allAlbums));
    }
  };

  useEffect(() => {
    theAlbums && setLatestAlbum((
      currentYear || currentHost
        ? theAlbums
        : allAlbums
      ).slice(0,1)[0]
    );
  }, [theAlbums]);

  const buildYears = (albums) => {
    if (albums.length > 0) {
      return buildDates(albums.slice(-1)[0].played_on, albums[0].played_on).reverse();
    }
    return [];
  };

  // Mode is a quick hack. "days" means format as far as days. Otherwise use all
  // formats up to seconds.
  const rangeToDuration = (from, to, mode) => {
    const dateFrom = new Date(from);
    const dateTo = new Date(to);
    const dur = toDuration(dateFrom, dateTo, mode);
    return dur;
  };

  const markupDuration = (duration) => {
    const units = duration.split(", ").map(unit => {
      const [count, name] = unit.split(" ");
      return (<React.Fragment key={name}>
        <strong>{count}</strong> {name}
      </React.Fragment>)
    });

    return units.reduce((prev, curr) => [prev, <React.Fragment key={curr}>, <br /></React.Fragment>, curr]);
  };

  return (
    <Layout>
      <div className={styles.el}>

      <Grid>

        <GridItem>

          {/* Albums */}
          <p className={cn(styles.stat, styles.big)}>
            <strong className={styles.huge}>
              {theAlbums && theAlbums.length}
            </strong> albums
          </p>

          {/* Artists */}
          <p className={cn(styles.stat, styles.big)}>
            <strong className={styles.huge}>
              {_.uniqBy(theAlbums, "artist").length}
            </strong> artists
          </p>

          {/* Hosted */}
          <p className={cn(styles.stat, styles.big)}>
            {markupDuration(
              rangeToDuration(
                theAlbums.slice(-1)[0].played_on,
                theAlbums[0].played_on
              )
            )}
          </p>

          {/* Tracks played */}
          {<p className={cn(styles.stat, styles.big)}>
            <strong className={styles.huge}>
              {theTracks.totalCount}
            </strong> tracks played
          </p>}

          {/* Listening time */}
          <p className={cn(styles.stat, styles.big)}>
            {markupDuration(
              secondsToDuration(theTracks.totalLength)
            )}
          </p>

          {/* Hosting time */}
          <p className={cn(styles.stat, styles.smallstat)}>
            From{" "}
            <time className={styles.strong}>{new Date(theAlbums.slice(-1)[0].played_on).toDateString()}</time>
            {" "}to{" "}
            <time className={styles.strong}>{new Date(theAlbums[0].played_on).toDateString()}</time>
          </p>

          <Icon icon="filter" className={cn(styles.filter, {
            [styles[`filterOn`]]: showFilters,
          })} onClick={() => setShowFilters(f => !f)} />

          {<ul className={cn(styles.list, {
            [styles[`listShow`]]: showFilters,
          })}>
            <li><a href={`#`} onClick={() => selectHost("")}>Everything</a></li>
            {theHosts.map(album => (
              <li key={album.number}>
                <a
                  id={album.host}
                  href={`#${album.host}`}
                  onClick={() => selectHost(album.host)}
                >
                  {album.host}
                </a>
              </li>
            ))}

            {buildYears(allAlbums).map(year => (
              <li key={year}>
                <a
                  id={year}
                  href={`#${year}`}
                  onClick={() => selectYear(year)}
                >
                  {year}
                </a>
              </li>
            ))}
          </ul>}

        </GridItem>

        <GridItem>
          {latestAlbum &&
            <>
              <Number data={latestAlbum} />
              <Album data={latestAlbum} />
            </>
          }
        </GridItem>

        {theAlbums.length > 1 && <GridItem>
          <h2 className={styles.heading2}>Covering {Object.keys(theYears.decades).length} decades</h2>
          <Graph
            className={styles.graph}
            data={theYears.decades}
          />

          <Grid small half>
            <GridItem>
              {/* Newest album - data incomplete */}
              <h2 className={styles.heading}>Newest album*</h2>
              {_.sortBy(theAlbums, "year").reverse().slice(0,1).map(album => (
                <Album data={album} year small key={album.number} suffix={` in ${album.year}`} />
              ))}
            </GridItem>

            <GridItem>
              {/* Oldest album - data incomplete */}
              <h2 className={styles.heading}>Oldest album*</h2>
              {_.sortBy(theAlbums, "year").slice(0,1).map(album => (
                <Album data={album} small key={album.number} suffix={` in ${album.year}`} />
              ))}
            </GridItem>

            <GridItem>
              {/* Contemporary album - data incomplete */}
              <h2 className={styles.heading}>Contemporary album*</h2>
              {_.sortBy(theAlbums, "age").slice(0,1).map(album => (
                <Album data={album} small key={album.number}
                  suffix={<span className={styles.suffix}>
                    {" "}played after{" "}
                    {markupDuration(
                      rangeToDuration(new Date(1970, 0, 1), album.age, "days"))
                    }
                  </span>}
                />
              ))}
            </GridItem>

            <GridItem>
              {/* Classic album - data incomplete */}
              <h2 className={styles.heading}>Classic album*</h2>
              {_.sortBy(theAlbums, ({ age }) => age || "").slice(-1).map(album => (
                <Album data={album} small key={album.number}
                  suffix={<span className={styles.suffix}>
                    {" "}played after{" "}
                    {markupDuration(
                      rangeToDuration(new Date(1970, 0, 1), album.age, "days"))
                    }
                  </span>}
                />))}
            </GridItem>

          </Grid>

          <p className={styles.note}>*Currently using year instead of release date due to incomplete data.</p>

          <Grid small half>

            <GridItem>
              {/* Shortest album */}
              <h2 className={styles.heading}>Shortest album</h2>
              <Album data={theTracks.byLength.filter(a => a.length > 0)[0]} small
                suffix={` at ${toMinutes(theTracks.byLength.filter(a => a.length > 0)[0].length)}`}
              />
            </GridItem>

            <GridItem>
              {/* Longest album */}
              <h2 className={styles.heading}>Longest album</h2>
              <Album data={theTracks.byLength.slice(-1)[0]} small
                suffix={` at ${toMinutes(theTracks.byLength.slice(-1)[0].length)}`}
              />
            </GridItem>

          </Grid>

        </GridItem>}

        {theAlbums.length > 1 && <GridItem>
          <h2 className={styles.heading2}>
            From {Object.keys(theYears.all).length} different years
            (spanning {Object.keys(theYears.all).slice(-1)[0]-Object.keys(theYears.all)[0]} years)
          </h2>
          <Graph
            className={styles.graph}
            data={theYears.all}
          />
        </GridItem>}

        </Grid>

        <h2 className={styles.heading}>Multiple appearances</h2>
        <Grid small>
          {Object.entries(byArtist(theAlbums))
            .reduce((artists, [artist, albums]) => (
              albums.length > 1
                ? [...artists, [artist, albums]]
                : artists
            ), [])
            .reverse()
            .map(([artist, albums], idx) => (
              <React.Fragment key={idx}>
                {albums.map(album => (
                  <GridItem key={album.number}>
                    <Album data={album} small />
                  </GridItem>
                ))}
              </React.Fragment>
            ))
          }
        </Grid>

        <h2 className={styles.heading}>Eponymously titled albums</h2>
        <Grid small>
        {theAlbums
          .reduce((albums, album) => (
            album.title === album.artist
              ? [...albums, album]
              : albums
          ), [])
          .map(album => (
            <GridItem key={album.number}>
              <Album data={album} small />
            </GridItem>
          )
        )}
        </Grid>

      </div>

      <div className={styles.footer}>
        <Link to="/archive" className={styles.archive}>
          <Icon icon="inventory_2" />
          Browse the archive
        </Link>
      </div>

    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allAlbumsYaml {
      nodes {
        artist
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cover_external
        host
        number
        played_on
        title
        year
        release_date
        tracks {
          duration
          name
          number
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export default Home;
